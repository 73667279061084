import React from "react"
import classes from "./PrivacyPolicy.module.css"

const PPContent = () => {
  return (
    <div className={classes.privacyRoot}>
      <div className={classes.privacyContainer}>
      <h1 className={classes.center}>Virgil Gamache Farms</h1>
      <h2 className={classes.center}>Privacy Policy</h2>
      <p>
        This Privacy Policy describes Virgil Gamache Farms, Inc.’s (“VGF”)
        practices regarding the collection, use, storage, sharing, and
        safeguarding of your information collected from any VGF-operated website
        or mobile application (collectively, the “Service”).
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        VGF may make changes to this Privacy Policy. The most current version of
        the Privacy Policy will govern VGF’s use of information about you and
        will be located at https://hoptechnics.com/privacy-policy. If VGF makes
        material changes to this Privacy Policy, VGF will notify you by posting
        a notice on the Service and sending notices to the contact information
        VGF has on file for you. Please ensure this information remains current
        so you will receive updates.
      </p>
      <h2>
        Categories of Personally Identifiable Information that VGF Collects
      </h2>
      <p>
        VGF may collect information that you provide to VGF in connection with
        your use of the Service when you: (A) register as a user of the Service;
        (B) make changes to your user profile information; (C) send email
        messages, forms, or other information to VGF or other users through the
        Service; or (D) interact with the Service. This information may include
        your first and last name, your address, email address, telephone number,
        and any additional information you provide to VGF when you register an
        account with VGF or otherwise interact with the Service.
      </p>
      <br />
      <br />
      <p>
        VGF may also collect certain technical information about your use of the
        Service. This technical information may include technical information
        about your device(s), browser type and version, geo-location
        information, computer and connection information, statistics on page
        views, traffic to and from the Service, ad data, Wi-Fi connection
        information, IP address, and standard web log information.
      </p>
      <br />
      <br />
      <p>
        The Service may allow you to share information with VGF that you have
        already provided to a third-party social networking platform. For
        example, you may be able to create an account on the Service or log in
        to the Service using your Facebook account, Google account, Apple ID, or
        other third-party credentials. If you do so, personal information or
        other data that you have provided to the third party may be made
        available to VGF by that third party subject to its own privacy policy,
        terms, and conditions. Privacy policies of social networks and other
        external platforms explain how those third parties use, protect, and
        share your information, including any information you share on those
        platforms from the Service, and VGF encourages you to read them. The
        information VGF receives from these platforms may depend on your privacy
        settings. Any such information that VGF obtains will remain subject to
        that third party’s privacy policy and VGF will use any such information
        in accordance with this Privacy Policy.{" "}
      </p>
      <h2>Tracking Technologies</h2>
      <p>
        From time to time, VGF may work with third-party service providers to
        help VGF better understand how you use the Service.
      </p>
      <br />
      <br />
      <p>
        VGF and these service providers may place tracking technologies on your
        device, such as cookies, web beacons, locally shared objects (sometimes
        called “flash cookies”), pixel tags, clear gifs, and similar
        technologies to collect information about you and your use of the
        Service. Cookies are small data files that may have unique identifiers,
        and reside, among other places, on your mobile device, in emails we send
        to you, and on our applications. Locally shared objects, or “flash
        cookies,” are data files that can be created on your computer by the
        websites you visit and are a way for websites to store information for
        later use. Locally stored objects are different than cookies because
        they are stored in different parts of your computer than cookies. Web
        beacons are small strings of code that provide a method for delivering a
        graphic image on a web page or in an email message for the purpose of
        transferring data. Pixel tags and clear gifs are message-sensing
        technologies that allow VGF to know if your e-mail client is able to
        accept HTML messages, and, if it is, send you messages in such a format
        and determine if you have opened the message. The information that is
        collected will tell VGF things like, your browser type, preferences,
        data relating to content that has been displayed to you or that you have
        clicked on, how you navigated around our Service, the location from
        where you accessed the Service, the date and time of your use, and how
        quickly VGF helped you to enter registration personal information. This
        information will help VGF to better serve you and improve the Service
        and may also be used in order to further features and processes on the
        Service, provide authentication and security for your Service accounts,
        store your preferences, facilitate relevant advertising, and help us
        learn more about how VGF’s users engage with the Website.
      </p>
      <br />
      <br />
      <p>
        VGF will not grant permission to any of these third-party service
        providers to collect your e-mail address or password information through
        this technology. Any third-party service provider will be restricted to
        use your personal information to perform services for VGF, securely and
        in confidence. Any such third-party service provider will be restricted
        from using your personal information for any other purpose, except on an
        aggregate, anonymous basis.
      </p>
      <br />
      <br />
      <p>
        Google, one of VGF’s service providers, has provided a description of
        how it collects information from its customer websites here:
        https://policies.google.com/technologies/partner-sites, and it has made
        available an opt out browser extension for Google Analytics, available
        here: https://tools.google.com/dlpage/gaoptout.
      </p>
      <h2>How VGF Uses Your Personally Identifiable Information</h2>
      <p>
        VGF may use your information to: (A) provide access to the Service to
        communicate with you; (B) personalize, customize, measure, and improve
        the Service; (C) prevent, detect, and investigate potentially prohibited
        or illegal activities or a breach of the applicable agreement(s) between
        you and VGF; (D) analyze the accuracy, effectiveness, usability, or
        popularity of the Service; (E) generate and review reports and data
        about VGF’s user base and service usage patterns; (F) compile aggregate,
        anonymized data for internal business purposes; and (G) resolve disputes
        and troubleshoot problems.
      </p>
      <h2>
        Categories of Third-Party Persons or Entities With Whom VGF May Share
        Your Personally Identifiable Information
      </h2>
      <p>
        VGF may disclose the information we collect from you to the following
        third parties:
      </p>
      <ul>
        <li>
          <span>Users of the Service; Public Information</span>. When you share
          information via the Service, such as your unique account
          identification code, VGF may share your information to fellow users of
          the Service, in accordance with the privacy settings you have chosen
          for your account or that are applicable to that information. To the
          extent you share any information to a public audience, that
          information will be available to anyone.
        </li>
        <br />
        <br />
        <li>
          <span>Third Party Integration</span>. When you use third-party
          products or services that integrate with the Service, we may share
          information about you with the owners or developers of those
          third-party products or services.
        </li>
        <br />
        <br />
        <li>
          <span>VGF's Service Providers</span>. VGF may share your information
          with third-party contractors, agents, collaborators, or service
          providers who provide certain services to VGF or on VGF’s behalf, such
          as operating and supporting the Service.
        </li>
        <br />
        <br />
        <li>
          <span>Companies that Acquire VGF’s Business or Assets</span>. If VGF
          becomes involved in a merger, acquisition, sale of assets, securities
          offering, bankruptcy, reorganization, or dissolution or if the
          ownership of all or substantially all of VGF’s business relating to
          the Service otherwise changes, VGF may provide your information to a
          third party or parties in connection with the applicable transaction.
        </li>
        <br />
        <br />
        <li>
          <span>Aggregate Information</span>. VGF may share information relating
          to visitors and users of the Service with affiliated or unaffiliated
          third parties on an aggregate basis. While this information will not
          identify you personally, in some instances these third parties may be
          able to combine this information with other data they have about you,
          or that they receive from third parties, in a manner that allows them
          to associate this aggregated data with your personal data.
        </li>
        <br />
        <br />
        <li>
          <span>Legal Requirements</span>. VGF may share your information with
          law enforcement, governmental agencies, or authorized third parties,
          in response to a request relating to a criminal investigation or
          alleged illegal activity or any other activity that may expose VGF,
          you, or any other VGF user to legal liability, or to protect VGF’s
          rights or property, or during emergencies when safety is at risk. VGF
          may also share your information in response to court orders,
          subpoenas, or other legal or regulatory requests, and VGF may provide
          access to your information to VGF’s legal counsel and other
          consultants in connection with actual or potential litigation.
        </li>
        <br />
        <br />
      </ul>
      <p>
        Your information may also be disclosed to third parties in unforeseeable
        situations or situations that are not preventable even when commercially
        reasonably protections are employed, such as in the case that VGF or the
        Service is subject to a hacking or other attack.
      </p>
      <h2>Do Not Track</h2>
      <p>
        You may be able to adjust your browser settings or other settings so
        that “do not track” requests are sent to the Service. VGF will not
        disable tracking technology that may be active on the Service in
        response to any “do not track” requests that are sent to the Service.
      </p>
      <h2>Security</h2>
      <p>
        VGF takes reasonable measures to protect the information you provide to
        VGF or submit through the Service against loss, theft, unauthorized use,
        disclosure, or modification. However, VGF cannot guarantee or warrant
        the security of any information you transmit to VGF or submit through
        the Service and you do so at your own risk.
      </p>
      <h2>Transfers to the United States</h2>
      <p>
        If you are interacting with the Service from outside the United States
        and provide VGF with any personal information, please note, in order for
        VGF to provide the Service or otherwise communicate with you as set
        forth in this Privacy Policy, it will be necessary for your personal
        information to be transferred, stored, and processed within the United
        States. The data protection laws in the United States may not be as
        comprehensive as those in your country. By doing business or interacting
        with VGF, you are consenting to the transfer of your personal
        information to facilities located in the United States and other
        facility locations selected by VGF.
      </p>
      <h2>Children's and Minor's Privacy</h2>
      <p>
        The Service is not designed to attract minors, in particular children
        under the age of 16. VGF does not market to or knowingly collect
        personal information from anyone under the age of 16. Children should
        always get permission from their parents before sending any personal
        information about themselves (such as their names, email addresses, and
        phone numbers) to anyone over the Internet.
      </p>
      <h2>Accessing, Changing and Managing Your Information.</h2>
      <ul>
        <li>
          <span>Changing or Updating Your Information</span>. You can access or
          change your personal profile and contact information or delete your
          account through your VGF personal settings page or by contacting VGF
          at eddie@divelement.io. If you choose to delete or deactivate your
          account, you can no longer retrieve your content or reactivate your
          account.
        </li>
        <br />
        <br />
        <li>
          <span>Retention of Your Information</span>. We retain information
          about you for as long as it is necessary and relevant for VGF’s
          operations and in accordance with applicable law. VGF may retain
          information collected from you to comply with the law, prevent fraud,
          resolve disputes, troubleshoot problems, assist with any
          investigation, and take other actions permitted by law or disclosed in
          this Privacy Policy.
        </li>
        <br />
        <br />
        <li>
          <span>Notice of Privacy Rights to California Residents</span>: Section
          1798.83 of the California Civil Code permits California residents to
          request from a business, with whom the California resident has an
          established business relationship, information related to the personal
          information disclosed by VGF to third parties for direct marketing
          purposes and the names and addresses of the third parties with whom
          the business has shared such information during the immediately
          preceding calendar year. You may make one request each year by
          emailing VGF at eddie@divelement.io.
        </li>
      </ul>
      <h2>Contact</h2>
      <p>
        If you have questions or complaints regarding VGF’s Privacy Policy or
        practices or about the information which we have collected from you,
        please contact VGF at eddie@divelement.io
      </p>
      <h2>Effective Date: April 28, 2020</h2>
      </div>
    </div>
  )
}

export default PPContent
