import React from "react"
import PPContent from "../components/PrivacyPolicy/PrivacyPolicy"

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <PPContent/>
    </React.Fragment>
  )
}

export default PrivacyPolicy
